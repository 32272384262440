import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Grid, FormControlLabel, Switch, Divider } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import {
  ParametreCode,
  ReferentialCode,
  OfferTypeCode,
  CurrencyCode,
  ReportTypeCode,
  Constants,
  AttachmentTypeCode,
  RenaultGoldList,
  PurchaseOriginCode,
  renaultMakeCodes
} from '../../helpers/Constants';
import { AppUser } from '../../interfaces/AppUser';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { CurrencyRate, VehicleOffer } from '../../interfaces/Vehicle';
import { Utils } from '../../helpers/Utils';
import { ReportParameter } from '../../interfaces/Reports';
import { IReportsService } from '../../services/Interfaces/IReportsService';
import FileSaver from 'file-saver';
import * as R from 'ramda';
import { isNullOrUndefined } from 'util';
import ContractModal from './ContractModal/ContractModal';
import { CaseTypeCode } from '../../helpers/Constants';
import { result } from 'lodash';
import { CurrencyEnum } from '../../enums/CurrencyEnum';
import _ from 'lodash';

interface ICaseDetailsCaseTabSaleOfferFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicleOfferData: VehicleOffer;
  guaranteeTypes: IReferential[];
  offerTypes: IReferential[];
  offers: VehicleOffer[];
  offerType: IReferential | null;
  contractOffer?: VehicleOffer;
  contractPrice?: number;
  contractModalOpened: boolean;
  displaySecondCurrency: boolean;
  purchaseOrigins: IReferential[];
  saleChannels: IReferential[];
  makes: IReferential[];
}

export interface ExternalCaseDetailsCaseTabSaleOfferFormProps {
  workflowForm: WorkflowSectionStepForm;
  isSaleOfferType: boolean;
}

type ICaseDetailsCaseTabSaleOfferFormProps = ExternalCaseDetailsCaseTabSaleOfferFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabSaleOfferForm extends React.PureComponent<
  ICaseDetailsCaseTabSaleOfferFormProps,
  ICaseDetailsCaseTabSaleOfferFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  private reportService!: IReportsService;
  private checkIfCurrencyIsEurOrRon: boolean =
    this.props.caseSettingsState.case!.caseParameters.find(
      (p) => p.parameter!.code === ParametreCode.CURRENCY
    )!.value == CurrencyCode.EUR ||
    this.props.caseSettingsState.case!.caseParameters.find(
      (p) => p.parameter!.code === ParametreCode.CURRENCY
    )!.value == CurrencyCode.RON;
  static contextType = ApplicationContext;
  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    guaranteeTypes: [] as IReferential[],
    offerTypes: [],
    offers: [],
    offerType: null,
    contractModalOpened: false,
    currencyRates: [],
    displaySecondCurrency: this.checkIfCurrencyIsEurOrRon,
    vehicleOfferData: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      vehicleId: this.props.caseSettingsState.case!.vehicleId,
      comments: '',
      damageValue: 0,
      newVin: '',
      offerValue: 0,
      offerValueWithVAT: 0,
      priceValue: 0,
      tradeInValue: 0,
      vatValue: 0,
      withNewVehicle: this.props.isSaleOfferType,
      currency: this.props.caseSettingsState.case!.caseParameters.find(
        (p) => p.parameter!.code === ParametreCode.CURRENCY
      )!.value,
      currencyRate: Utils.GetCurrencyRate(
        this.props.appState.currencyRates,
        this.props.caseSettingsState.case!.caseParameters.find(
          (p) => p.parameter!.code === ParametreCode.CURRENCY
        )!.value == CurrencyCode.RON
          ? CurrencyCode.EUR
          : this.props.caseSettingsState.case!.caseParameters.find(
              (p) => p.parameter!.code === ParametreCode.CURRENCY
            )!.value
      ),
      secondCurrency: this.checkIfCurrencyIsEurOrRon
        ? this.props.caseSettingsState.case!.caseParameters.find(
            (p) => p.parameter!.code === ParametreCode.CURRENCY
          )!.value == CurrencyCode.EUR
          ? CurrencyCode.RON
          : CurrencyCode.EUR
        : null,
      secondOfferValue: this.checkIfCurrencyIsEurOrRon ? 0 : null,
      secondOfferVatAmount: this.checkIfCurrencyIsEurOrRon ? 0 : null,
      secondOfferValueWithVAT: this.checkIfCurrencyIsEurOrRon ? 0 : null
    } as VehicleOffer,
    purchaseOrigins: [] as IReferential[],
    saleChannels: [] as IReferential[],
    makes: [] as IReferential[]
  } as ICaseDetailsCaseTabSaleOfferFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    // let caseParametersList = this.props.caseSettingsState.case!.caseParameters;
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseSaleOfferForm();
        if (!this.props.appState.currencyRates.length) {
          await this.getCurrencyRates();
        }
      }
    );
  }

  closeContractModal = () => {
    this.setState({
      contractModalOpened: false
    });
  };

  getCurrencyRates = async () => {
    const rates = await this.vehicleService.GetCurrencyRates();
    this.props.appState.currencyRates = rates;
    console.log('currencyRates', this.props.appState.currencyRates);
    this.setState({
      vehicleOfferData: {
        ...this.state.vehicleOfferData,
        currencyRate: Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          this.props.caseSettingsState.case!.caseParameters.find(
            (p) => p.parameter!.code === ParametreCode.CURRENCY
          )!.value == CurrencyCode.RON
            ? CurrencyCode.EUR
            : this.props.caseSettingsState.case!.caseParameters.find(
                (p) => p.parameter!.code === ParametreCode.CURRENCY
              )!.value
        )
      }
    });
  };

  openContractModal = async (contractOffer: VehicleOffer, contractPrice: number) => {
    const caseContractAttachments = await this.caseService.GetCaseAttachments(
      this.props.caseSettingsState.case!.id,
      [AttachmentTypeCode.CONTRACT]
    );

    const fileName = `Contract-${contractOffer.id}.pdf`;
    const attachment = caseContractAttachments.find((a) => a.originalFileName === fileName);

    if (!caseContractAttachments || !caseContractAttachments.length || !attachment) {
      this.setState({
        contractModalOpened: true,
        contractPrice,
        contractOffer
      });
    } else {
      if (attachment) {
        const attachmentBlob = await this.caseService.GetCaseAttachment(attachment.fileName);

        FileSaver.saveAs(attachmentBlob, attachment.originalFileName);
      } else {
        throw Error(`Attachment ${fileName}`);
      }
    }
  };

  getCurrentCurrency = (): string => {
    return this.props.caseSettingsState.case!.caseParameters.find(
      (p) => p.parameter!.code === ParametreCode.CURRENCY
    )!.value;
  };

  getVATValue = (price: number, vat: number): number => {
    return Number.parseFloat((price * (vat / 100)).toFixed(2));
  };

  getPriceWithVAT = (price: number, vat: number) => {
    const vatValue = this.getVATValue(price, vat);
    return Number.parseFloat((price + vatValue).toFixed(2));
  };

  getReverseVatValue = (price: number, vat: number): number => {
    return Number.parseFloat(((price / (1 + vat / 100) - price) * -1).toFixed(2));
  };

  getPriceWithoutVAT = (price: number, vat: number) => {
    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const vatValue = !vehicleHasNoVAT
      ? this.getReverseVatValue(price, vat)
      : Utils.getMarginVat(price, +purchasePrice, vat);

    return Number.parseFloat((price - vatValue).toFixed(2));
  };

  getOfferPriceWithoutVAT = (offerPrice: number): number => {
    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );
    const vat = parseFloat(vatParameter[0]!.value);

    const vatValue = !vehicleHasNoVAT
      ? this.getReverseVatValue(offerPrice, vat)
      : Utils.getMarginVat(offerPrice, +purchasePrice, vat);

    return Number.parseFloat((offerPrice - vatValue).toFixed(2));
  };

  getVATFromOfferPrice = () => {
    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const offerPrice = this.state.vehicleOfferData.offerValueWithVAT;
    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );

    const vat = parseFloat(vatParameter[0]!.value);

    if (!vehicleHasNoVAT) {
      const offerPriceWithoutVAT = offerPrice / (1 + vat / 100);

      return Number.parseFloat((offerPriceWithoutVAT * (vat / 100)).toFixed(2));
    }

    return Utils.getMarginVat(offerPrice, purchasePrice, vat);
  };

  getValuationPrice = (caseHasVAT: boolean) => {
    const caseValuation = this.props.caseSettingsState.case?.caseValuation;
    let valuationValue = caseValuation
      ? this.props.isSaleOfferType
        ? this.props.caseSettingsState.case!.caseValuation?.offerPrice || 0
        : this.props.caseSettingsState.case!.caseValuation?.tradeInPrice || 0
      : 0;

    const caseValuationVatValue = caseValuation?.vatRate || 0;

    if (!caseHasVAT) {
      valuationValue = Utils.getPriceWithoutVat(valuationValue, caseValuationVatValue);
    }

    return Utils.ConvertCurrency(
      this.props.appState.currencyRates,
      CurrencyCode.EUR,
      this.state.vehicleOfferData.currency,
      valuationValue
    );
  };

  setStatePriceAndVAT = () => {
    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );

    const vatValue = +vatParameter[0]?.value || 0;
    const caseHasNoVAT = vatValue === 0;

    const tradeInValue = this.getValuationPrice(!caseHasNoVAT);

    this.setState({
      vehicleOfferData: {
        ...this.state.vehicleOfferData,
        offerType: this.state.offerType,
        offerTypeId: this.state.offerType!.id,
        tradeInValue,
        damageValue: this.getDamageValue(),
        offerValue: 0,
        offerValueWithVAT: this.getPriceWithVAT(0, vatValue),
        priceValue: Number.parseFloat(
          (this.getPriceWithoutVAT(tradeInValue, vatValue) - this.getDamageValue()).toFixed(2)
        ),
        vatValue: parseFloat(vatParameter[0]!.value)
      }
    });
  };

  getDamageValue = () => {
    if (R.isNil(this.props.caseSettingsState.case!.caseCalculation)) {
      return 0;
    }

    const damage = this.props.caseSettingsState.case!.caseCalculation.amountWithoutTVA;

    return Utils.ConvertCurrency(
      this.props.appState.currencyRates,
      this.props.caseSettingsState.case!.caseCalculation.currencyCode,
      this.getCurrentCurrency(),
      damage
    );
  };

  setSecondValueBasedOnCaseCurrency(value: number, currencyRate: number): number | null {
    let resultValue = null;
    if (
      this.state.vehicleOfferData.currency == CurrencyCode.EUR ||
      this.state.vehicleOfferData.currency == CurrencyCode.RON
    ) {
      resultValue = 0;
      const previousCurrencyCode = this.state.vehicleOfferData.currency;
      const futureCurrencyCode =
        this.state.vehicleOfferData.currency == CurrencyCode.EUR
          ? CurrencyCode.RON
          : CurrencyCode.EUR;
      if (!R.isNil(value) && previousCurrencyCode) {
        let previousCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          previousCurrencyCode
        );

        let futureCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          futureCurrencyCode
        );

        if (this.state.vehicleOfferData.currency == CurrencyEnum.EUR) {
          previousCurrencyRate = currencyRate;
        } else if (this.state.vehicleOfferData.currency == CurrencyCode.RON) {
          futureCurrencyRate = currencyRate;
        }

        resultValue = value * (previousCurrencyRate / futureCurrencyRate);
        return Number.parseFloat(resultValue.toFixed(2));
      }
    }

    return resultValue;
  }

  setSecondVatFromOfferPrice(offerValueWithVAT: number): number {
    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;
    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;
    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;
    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );
    const vat = parseFloat(vatParameter[0]!.value);
    if (!vehicleHasNoVAT) {
      const offerPriceWithoutVAT = offerValueWithVAT / (1 + vat / 100);
      return Number.parseFloat((offerPriceWithoutVAT * (vat / 100)).toFixed(2));
    }
    return Utils.getMarginVat(offerValueWithVAT, purchasePrice, vat);
  }

  loadCaseSaleOfferForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const guaranteeTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.GuaranteeType
    );
    const offerTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OfferTye
    );

    const purchaseOriginRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PurchaseOrigin
    );

    const saleChannelRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.SaleChannel
    );

    const vehicleMakeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.VehicleMake
    );

    const offerTypeList = await this.appReferentialService.Get(offerTypeRef!.baseUrl);

    const offerType = offerTypeList.find(
      (item) =>
        (item.code === OfferTypeCode.SELL && this.props.isSaleOfferType) ||
        (item.code === OfferTypeCode.BUY && !this.props.isSaleOfferType)
    )!;
    const [guaranteeTypeList, offers, puchaseOriginList, saleChannelList, makeList] = await Promise.all([
      this.appReferentialService.Get(guaranteeTypeRef!.baseUrl),
      R.isNil(this.props.caseSettingsState.case!.vehicleId)
        ? []
        : this.vehicleService.GetVehicleOffers(
            this.props.caseSettingsState.case!.vehicleId,
            this.props.caseSettingsState.case!.id,
            offerType.id
          ),
      this.appReferentialService.Get(purchaseOriginRef!.baseUrl),
      this.appReferentialService.Get(saleChannelRef!.baseUrl),
      this.appReferentialService.Get(vehicleMakeRef!.baseUrl)
    ]);

    const users = await this.appUserService.GetUsersInfo(offers.map((item) => item.createdBy));
    offers.forEach((item) => {
      const user = users.find((u) => u.id === item.createdBy);
      item.createdByUser = R.isNil(user) ? null : user;
    });

    let makeListFiltered = _.cloneDeep(makeList.filter((item) => item.isActive));
    if (this.props.appState.appUser!.organization!.dealerId && !this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert) {
      makeListFiltered = makeListFiltered.filter(
        (item) => item.code && renaultMakeCodes.includes(item!.code)
      );
    }

    this.setState(
      {
        isLoading: false,
        hasRights: hasRights,
        isUserHidden: !hasRights,
        guaranteeTypes: guaranteeTypeList.filter((item) => item.isActive),
        offerTypes: offerTypeList,
        offers: offers,
        offerType: offerType,
        purchaseOrigins: puchaseOriginList.filter((item) => item.isActive),
        saleChannels: saleChannelList.filter((item) => item.isActive),
        makes: makeListFiltered
      },
      this.setStatePriceAndVAT
    );
  };

  loadOffers = async () => {
    const offers = await this.vehicleService.GetVehicleOffers(
      this.props.caseSettingsState.case!.vehicleId!,
      this.props.caseSettingsState.case!.id,
      this.state.offerType!.id
    );

    const users = await this.appUserService.GetUsersInfo(offers.map((item) => item.createdBy));
    offers.forEach((item) => {
      const user = users.find((u) => u.id === item.createdBy);
      item.createdByUser = R.isNil(user) ? null : user;
    });

    this.setState({
      offers: offers
    });
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  NumberFormatCustomCurrencyRate = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={4}
      />
    );
  };

  submitData = async (e: any) => {
    try {
      this.setState({ executing: true });
      const data: VehicleOffer = {
        ...this.state.vehicleOfferData,
        offerVatAmount:
          this.state.vehicleOfferData.offerValueWithVAT - this.state.vehicleOfferData.offerValue,
        secondCurrency: this.state.displaySecondCurrency
          ? this.state.vehicleOfferData.secondCurrency
          : null,
        secondOfferValue: this.state.displaySecondCurrency
          ? this.state.vehicleOfferData.secondOfferValue
          : null,
        secondOfferValueWithVAT: this.state.displaySecondCurrency
          ? this.state.vehicleOfferData.secondOfferValueWithVAT
          : null
      };

      await this.vehicleService.SaveVehicleOffer(data);

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      await this.loadOffers();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  handleGuaranteeTypeChange = (newValue: IReferential | null) => {
    if (!R.isNil(newValue!.displayName)) {
      if (RenaultGoldList.includes(newValue!.code!.toString())) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'RENEW_GOLD_INFO_MESSAGE',
            'Aceasta  garantie include si Garantia de producator !'
          ),
          {
            variant: 'info',
            autoHideDuration: 5000
          }
        );
      }
      this.setState({
        vehicleOfferData: {
          ...this.state.vehicleOfferData,
          guaranteeType: newValue,
          guaranteeTypeId: newValue === null ? null : newValue.id
        }
      });
    }
  };


  handlePurchaseOriginChange = (newValue: IReferential | null) => {
    if (!R.isNil(newValue!.displayName)) {
      this.setState({
        vehicleOfferData: {
          ...this.state.vehicleOfferData,
          purchaseOrigin: newValue,
          purchaseOriginId: newValue === null ? null : newValue.id,
          withNewVehicle: newValue && newValue.code === PurchaseOriginCode.NC ? true : false
        }
      });
    }
  };

  
  handleMakeChange = (newValue: IReferential | null) => {
    if (!R.isNil(newValue!.displayName)) {
      this.setState({
        vehicleOfferData: {
          ...this.state.vehicleOfferData,
          make: newValue,
          makeId: newValue === null ? null : newValue.id
        }
      });
    }
  };

  handleSaleChannelChange = (newValue: IReferential | null) => {
    if (!R.isNil(newValue!.displayName)) {
      this.setState({
        vehicleOfferData: {
          ...this.state.vehicleOfferData,
          saleChannel: newValue,
          saleChannelId: newValue === null ? null : newValue.id
        }
      });
    }
  };

  handleIsActiveNewVehicle = (e: any) => {
    //in acest caz vom prelua automat  ca si origine achizitie  / by default - Trade In VN  - Achizitie de vehicul rulat ( VO) cu vanzare de vehicul nou ( VN)
    let purchaseOrigin: IReferential | null = null;
    if (e.currentTarget.checked && !this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert) {
      purchaseOrigin = this.state.purchaseOrigins.find((item) => item.code === PurchaseOriginCode.NC) || null;
    }

    this.setState({
      vehicleOfferData: {
        ...this.state.vehicleOfferData,
        withNewVehicle: e.currentTarget.checked,
        newVin: e.currentTarget.checked ? this.state.vehicleOfferData.newVin : '',
        purchaseOrigin: e.currentTarget.checked ? purchaseOrigin : null,
        purchaseOriginId: e.currentTarget.checked ? (purchaseOrigin?.id ?? null) : null,
      }
    });
  };

  getInvoicePdf = async (
    id: number,
    vehicleId: number,
    caseId: number,
    caseClientHistoryId: number | null
  ) => {
    const reportParameters = [
      {
        name: 'vehicleId',
        value: vehicleId.toString()
      } as ReportParameter,
      {
        name: 'caseId',
        value: caseId.toString()
      } as ReportParameter,
      {
        name: 'offerId',
        value: id.toString()
      } as ReportParameter,
      {
        name: 'organizationId',
        value: this.props.appState.appUser!.organization?.id.toString()
      } as ReportParameter,
      {
        name: 'caseClientHistoryId',
        value: caseClientHistoryId?.toString()
      } as ReportParameter,
      {
        name: 'apiUrl',
        value: Constants.apiRoot
      } as ReportParameter
    ];

    const fileBlob = await this.reportService.GetReport(
      ReportTypeCode.RENAULT_INVOICE,
      reportParameters
    );
    FileSaver.saveAs(fileBlob, 'Proforma invoice.pdf');
  };

  getOfferPdf = async (id: number, vehicleId: number, caseId: number) => {
    const reportParameters = [
      {
        name: 'vehicleId',
        value: vehicleId.toString()
      } as ReportParameter,
      {
        name: 'caseId',
        value: caseId.toString()
      } as ReportParameter,
      {
        name: 'calculationId',
        value: this.props.caseSettingsState.case?.caseCalculation?.id.toString() || null
      } as ReportParameter,
      {
        name: 'offerId',
        value: id.toString()
      } as ReportParameter,
      {
        name: 'organizationId',
        value: this.props.appState.appUser!.organization?.id.toString()
      } as ReportParameter,

      {
        name: 'apiUrl',
        value: Constants.apiRoot
      } as ReportParameter,
      {
        name: 'organizationOwnerId',
        value: this.props.caseSettingsState.case?.organizationOwnerId.toString()
      } as ReportParameter,
      {
        name: 'hideOrganizationLogo',
        value: this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert ? 'true' : 'false'
      } as ReportParameter
    ];

    const fileBlob = await this.reportService.GetReport(
      ReportTypeCode.RENAULT_OFFER,
      reportParameters
    );
    FileSaver.saveAs(fileBlob, 'Offer.pdf');
  };

  showGetInvoiceButton = (offer: VehicleOffer) => {
    const workflowSteps = this.props.caseSettingsState.caseSettings?.workflow.workflowSections;
    if (!isNullOrUndefined(workflowSteps)) {
      const code17Steps = workflowSteps?.find((wfs) => {
        const codeF17 = wfs.workflowSectionSteps.find((x) =>
          x.workflowSectionStepForms.find((wfssf) => wfssf.form.code === 'F17')
        );
        return !isNullOrUndefined(codeF17);
      });
      if (!isNullOrUndefined(code17Steps)) {
        return (
          <Button
            className="m-2"
            variant="outlined"
            color="secondary"
            disabled={!this.state.hasRights || this.state.executing}
            onClick={() =>
              this.getInvoicePdf(offer.id, offer.vehicleId, offer.caseId, offer.caseClientHistoryId)
            }
          >
            {this.translatorService.Tranlate('SALE_OFFER_INVOICE_PROFORMA', 'Factura Proforma')}
          </Button>
        );
      }
    }
    return null;
  };

  showOfferTimeline = (caseHasVAT: boolean) => {
    const priceLabel = caseHasVAT
      ? this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED_WITH_VAT', 'Pret calculat cu TVA')
      : this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED', 'Pret calculat cu TVA');

    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    return this.state.offers.map((offer, index) => {
      const price = !vehicleHasNoVAT
        ? this.getPriceWithVAT(offer.offerValue, this.state.vehicleOfferData.vatValue)
        : offer.offerValue + offer.offerVatAmount;
      const secondPrice = !vehicleHasNoVAT
        ? offer.secondOfferValue
          ? this.getPriceWithVAT(offer.secondOfferValue, this.state.vehicleOfferData.vatValue)
          : null
        : !R.isNil(offer.secondOfferValue) && !R.isNil(offer.secondOfferVatAmount)
        ? offer.secondOfferValue + offer.secondOfferVatAmount
        : null;
      return (
        <div key={index} className="timeline-item">
          <div className="timeline-item--content">
            <div className="timeline-item--icon " />
            <p className="timeline-item--content font-weight-bold">
              {moment.utc(offer.dateCreation).local().format(this.props.appState.longDateFormat) +
                ' (' +
                offer.createdByUser!.email +
                ')'}
            </p>
            <p className="timeline-item--label mb-2 font-weight-bold">
              {`${priceLabel}: ${price} ${offer.currency}`}
              {secondPrice ? '(' + secondPrice + offer.secondCurrency + ')' : ''}
            </p>

            {offer.purchaseOrigin != null && (
              <p className="timeline-item--label mb-2 font-weight-bold">
                {' '}
                {this.translatorService.Tranlate('SALE_OFFER_PURCHASE_ORIGIN', 'Origine achizitie')}:
                {offer.purchaseOrigin!.displayName ? ' ' + offer.purchaseOrigin!.displayName : null}
              </p>
            )}

            {offer.saleChannel != null && (
              <p className="timeline-item--label mb-2 font-weight-bold">
                {' '}
                {this.translatorService.Tranlate('SALE_OFFER_SALE_CHANNEL', 'Canal vanzare')}:
                {offer.saleChannel!.displayName ? ' ' + offer.saleChannel!.displayName : null}
              </p>
            )}

            {offer.guaranteeType != null && (
              <p className="timeline-item--label mb-2 font-weight-bold">
                {' '}
                {this.translatorService.Tranlate('SALE_OFFER_GUARANTEE_TYPE', 'Tip garantie')}:
                {offer.guaranteeType!.displayName ? ' ' + offer.guaranteeType!.displayName : null}
              </p>
            )}

            <Grid container spacing={0}>
              <Grid item xs={4}>
                {this.showGetInvoiceButton(offer)}
              </Grid>
              <Grid item xs={4}>
                <Button
                  className="m-2"
                  variant="outlined"
                  color="secondary"
                  disabled={this.state.executing}
                  onClick={() => this.getOfferPdf(offer.id, offer.vehicleId, offer.caseId)}
                >
                  {this.translatorService.Tranlate('SALE_OFFER_INVOICE_PDF', 'Oferta PDF')}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  className="m-2"
                  variant="outlined"
                  color="secondary"
                  disabled={this.state.executing}
                  onClick={() => this.openContractModal(offer, price)}
                >
                  {this.translatorService.Tranlate('SALE_OFFER_CONTRACT', 'Contract')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    });
  };

  renderClientDataForm = (caseHasVAT: boolean) => {
    const saleOfferPriceWithoutVatLabel = caseHasVAT
      ? this.translatorService.Tranlate('SALE_OFFER_PRICE_WITHOUT_VAT', 'Pret recomandat fara TVA')
      : this.translatorService.Tranlate('SALE_OFFER_PRICE', 'Pret recomandat');

    const saleOfferPriceOfferedWithoutVatLabel = caseHasVAT
      ? this.translatorService.Tranlate(
          'SALE_OFFER_PRICE_OFFERED_WITHOUT_VAT',
          'Pret ofertat fara TVA'
        )
      : this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED', 'Pret ofertat');

    const saleOfferPriceOfferedWithVatLabel = caseHasVAT
      ? this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED_WITH_VAT', 'Pret ofertat cu TVA')
      : this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED', 'Pret ofertat');

    const saleOfferCalculatedPriceWithoutVat = caseHasVAT
      ? this.translatorService.Tranlate('SALE_OFFER_CALCULATED_PRICE_WITHOUT_VAT', 'Pret calculat')
      : this.translatorService.Tranlate('SALE_OFFER_CALCULATED_PRICE', 'Pret calculat');

    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const vehicleHasNoVAT =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitData(e);
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={0}>
            <Grid item xs={caseHasVAT ? 4 : 12}>
              <TextValidator
                fullWidth
                disabled
                id="price-without-vat"
                className="m-2 pr-3"
                name="priceWithoutVat"
                placeholder={saleOfferPriceWithoutVatLabel}
                value={
                  this.state.displaySecondCurrency
                    ? this.getPriceWithoutVAT(
                        this.state.vehicleOfferData.tradeInValue,
                        this.state.vehicleOfferData.vatValue
                      ) +
                      ' ' +
                      this.state.vehicleOfferData.currency
                    : this.getPriceWithoutVAT(
                        this.state.vehicleOfferData.tradeInValue,
                        this.state.vehicleOfferData.vatValue
                      )
                }
                label={saleOfferPriceWithoutVatLabel}
              />
            </Grid>
            {caseHasVAT && (
              <>
                <Grid item xs={4}>
                  <TextValidator
                    fullWidth
                    disabled
                    id="vat"
                    className="m-2 pr-3"
                    name="vat"
                    placeholder={this.translatorService.Tranlate('SALE_OFFER_VAT', 'TVA')}
                    value={
                      !vehicleHasNoVAT
                        ? this.state.displaySecondCurrency
                          ? this.getReverseVatValue(
                              this.state.vehicleOfferData.tradeInValue,
                              this.state.vehicleOfferData.vatValue
                            ).toFixed(2) +
                            ' ' +
                            this.state.vehicleOfferData.currency
                          : this.getReverseVatValue(
                              this.state.vehicleOfferData.tradeInValue,
                              this.state.vehicleOfferData.vatValue
                            ).toFixed(2)
                        : this.state.displaySecondCurrency
                        ? Utils.getMarginVat(
                            this.state.vehicleOfferData.tradeInValue,
                            +purchasePrice,
                            this.state.vehicleOfferData.vatValue
                          ).toFixed(2) +
                          ' ' +
                          this.state.vehicleOfferData.currency
                        : Utils.getMarginVat(
                            this.state.vehicleOfferData.tradeInValue,
                            +purchasePrice,
                            this.state.vehicleOfferData.vatValue
                          ).toFixed(2)
                    }
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate('SALE_OFFER_VAT', 'TVA')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextValidator
                    fullWidth
                    disabled
                    id="price-with-vat"
                    className="m-2 pr-3"
                    name="priceWithVat"
                    placeholder={this.translatorService.Tranlate(
                      'SALE_OFFER_PRICE_VAT_INCLUDED',
                      'Pret recomandat cu TVA'
                    )}
                    value={
                      this.state.displaySecondCurrency
                        ? this.state.vehicleOfferData.tradeInValue.toFixed(2) +
                          ' ' +
                          this.state.vehicleOfferData.currency
                        : this.state.vehicleOfferData.tradeInValue.toFixed(2)
                    }
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate(
                      'SALE_OFFER_PRICE_VAT_INCLUDED',
                      'Pret recomandat cu TVA'
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>

          {this.props.isSaleOfferType === false ? (
            <div>
              <TextValidator
                fullWidth
                disabled
                id="damage-value"
                className="m-2 pr-3"
                name="damageValue"
                placeholder={this.translatorService.Tranlate(
                  'SALE_OFFER_DAMAGE_VALUE',
                  'Valoare dauna'
                )}
                value={
                  this.state.displaySecondCurrency
                    ? this.state.vehicleOfferData.damageValue +
                      ' ' +
                      this.state.vehicleOfferData.currency
                    : this.state.vehicleOfferData.damageValue
                }
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('SALE_OFFER_DAMAGE_VALUE', 'Valoare dauna')}
              />

              <TextValidator
                fullWidth
                disabled
                id="calculated-price"
                className="m-2 pr-3"
                name="calculatedPrice"
                placeholder={saleOfferCalculatedPriceWithoutVat}
                value={
                  this.state.displaySecondCurrency
                    ? this.state.vehicleOfferData.priceValue +
                      ' ' +
                      this.state.vehicleOfferData.currency
                    : this.state.vehicleOfferData.priceValue
                }
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={saleOfferCalculatedPriceWithoutVat}
              />
            </div>
          ) : (
            ''
          )}

          <Grid container spacing={0}>
            <Grid item xs={caseHasVAT ? 6 : 12}>
              <TextValidator
                fullWidth
                disabled
                id="price-offered-without-vat"
                className="m-2 pr-3"
                name="priceOfferedWithoutVat"
                placeholder={saleOfferPriceOfferedWithoutVatLabel}
                value={
                  this.state.displaySecondCurrency
                    ? this.getOfferPriceWithoutVAT(this.state.vehicleOfferData.offerValueWithVAT) +
                      ' ' +
                      this.state.vehicleOfferData.currency +
                      ' (' +
                      this.state.vehicleOfferData?.secondOfferValue +
                      ' ' +
                      this.state.vehicleOfferData?.secondCurrency +
                      ')'
                    : this.getOfferPriceWithoutVAT(this.state.vehicleOfferData.offerValueWithVAT)
                }
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={saleOfferPriceOfferedWithoutVatLabel}
              />
            </Grid>
            {caseHasVAT && (
              <Grid item xs={6}>
                <TextValidator
                  fullWidth
                  disabled
                  id="price-offered-vat"
                  className="m-2 pr-3"
                  name="priceOfferedVat"
                  placeholder={this.translatorService.Tranlate(
                    'SALE_OFFER_PRICE_OFFERED_VAT',
                    'TVA'
                  )}
                  value={
                    this.state.displaySecondCurrency
                      ? this.getVATFromOfferPrice().toFixed(2) +
                        ' ' +
                        this.state.vehicleOfferData.currency +
                        ' (' +
                        this.state.vehicleOfferData?.secondOfferVatAmount +
                        ' ' +
                        this.state.vehicleOfferData.secondCurrency +
                        ')'
                      : this.getVATFromOfferPrice().toFixed(2)
                  }
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate('SALE_OFFER_PRICE_OFFERED_VAT', 'TVA')}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                variant="outlined"
                disabled={!this.state.hasRights}
                id="price-offered-with-vat"
                className="m-2 pr-3"
                name="priceOfferedWithVat"
                placeholder={saleOfferPriceOfferedWithVatLabel}
                value={this.state.vehicleOfferData.offerValueWithVAT}
                onChange={(e: any) => {
                  const offerValue = this.getOfferPriceWithoutVAT(e.target.value);
                  const secondOfferValueWithVAT = this.setSecondValueBasedOnCaseCurrency(
                    e.target.value,
                    this.state.vehicleOfferData.currencyRate
                  );
                  const secondOfferValue = secondOfferValueWithVAT
                    ? this.getOfferPriceWithoutVAT(secondOfferValueWithVAT)
                    : 0;
                  const secondOfferVatAmount = secondOfferValueWithVAT
                    ? this.setSecondVatFromOfferPrice(secondOfferValueWithVAT)
                    : 0;
                  this.setState({
                    vehicleOfferData: {
                      ...this.state.vehicleOfferData,
                      offerValueWithVAT: e.target.value,
                      offerValue: offerValue,
                      secondOfferValueWithVAT: secondOfferValueWithVAT
                        ? secondOfferValueWithVAT
                        : 0,
                      secondOfferValue: secondOfferValue,
                      secondOfferVatAmount: secondOfferVatAmount
                    }
                  });
                }}
                validators={['required', 'minNumber:1']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={
                  this.state.displaySecondCurrency
                    ? saleOfferPriceOfferedWithVatLabel +
                      ' | ' +
                      this.state.vehicleOfferData.offerValueWithVAT +
                      ' ' +
                      this.state.vehicleOfferData.currency +
                      ' (' +
                      this.state.vehicleOfferData.secondOfferValueWithVAT +
                      ' ' +
                      this.state.vehicleOfferData.secondCurrency +
                      ')'
                    : saleOfferPriceOfferedWithVatLabel
                }
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                  style: { fontWeight: 500 }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                variant="outlined"
                disabled={!this.state.hasRights}
                id="price-offered-with-vat"
                className="m-2"
                name="currency"
                placeholder={this.translatorService.Tranlate(
                  'SALE_OFFER_PRICE_FXRATE',
                  'Curs valutar'
                )}
                value={this.state.vehicleOfferData.currencyRate}
                onChange={(e: any) => {
                  const value = e.target.value;
                  const secondOfferValueWithVAT = this.setSecondValueBasedOnCaseCurrency(
                    this.state.vehicleOfferData.offerValueWithVAT,
                    parseFloat(value)
                  );
                  const secondOfferValue = secondOfferValueWithVAT
                    ? this.getOfferPriceWithoutVAT(secondOfferValueWithVAT)
                    : 0;
                  console.log(secondOfferValue);
                  this.setState({
                    vehicleOfferData: {
                      ...this.state.vehicleOfferData,
                      currencyRate: parseFloat(value),
                      secondOfferValueWithVAT: secondOfferValueWithVAT
                        ? secondOfferValueWithVAT
                        : 0,
                      secondOfferValue: secondOfferValue ? secondOfferValue : 0,
                      secondOfferVatAmount: secondOfferValueWithVAT
                        ? this.setSecondVatFromOfferPrice(secondOfferValueWithVAT)
                        : 0
                    }
                  });
                }}
                validators={['required', 'minNumber:1']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('SALE_OFFER_PRICE_FXRATE', 'Curs valutar')}
                InputProps={{
                  inputComponent: this.NumberFormatCustomCurrencyRate,
                  style: { fontWeight: 500 }
                }}
              />
            </Grid>
          </Grid>

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="observations"
            className="m-2 pr-3"
            name="observations"
            placeholder={this.translatorService.Tranlate('SALE_OFFER_OBSERVATIONS', 'Observatii')}
            value={this.state.vehicleOfferData.comments}
            onChange={(e: any) => {
              this.setState({
                vehicleOfferData: {
                  ...this.state.vehicleOfferData,
                  comments: e.target.value
                }
              });
            }}
            validators={[]}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            label={this.translatorService.Tranlate('SALE_OFFER_OBSERVATIONS', 'Observatii')}
            multiline
            variant="outlined"
            rows={6}
          />

          {this.props.isSaleOfferType === false ? (
            <>
            <div>
              <div className="text-left">
                <FormControlLabel
                  control={<Switch
                    disabled={!this.state.hasRights}
                    checked={this.state.vehicleOfferData.withNewVehicle}
                    onChange={(e: any) => this.handleIsActiveNewVehicle(e)}
                    color="primary" />}
                  label={this.translatorService.Tranlate(
                    'SALE_OFFER_WITH_SALE_NEW_VEHICLE',
                    'Cu vanzare vehicul nou'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0" />
              </div>

              <Autocomplete
                disabled={!this.state.hasRights}
                id="brand"
                className="m-2"
                options={this.state.makes}
                value={this.state.vehicleOfferData.make || null}
                onChange={(e: any, newValue: IReferential | null) => this.handleMakeChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="brand"
                    value={this.state.vehicleOfferData.make || null}
                    label={this.translatorService.Tranlate('CAR_DETAILS_NEW_BRAND', 'Marca - autovehicul nou')}
                    fullWidth
                    validators={this.state.vehicleOfferData.withNewVehicle ? ['required'] : []}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]} />
                )} />
            </div>
            {this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert ? null : (
              <div>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="purchase-origin"
                  className="m-2"
                  options={this.state.purchaseOrigins}
                  value={this.state.vehicleOfferData.purchaseOrigin || null}
                  onChange={(e: any, newValue: IReferential | null) => this.handlePurchaseOriginChange(newValue)}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="purchase-origin"
                      value={this.state.vehicleOfferData!.purchaseOrigin || null}
                      label={this.translatorService.Tranlate(
                        'SALE_OFFER_PURCHASE_ORIGIN',
                        'Origine achizitie'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]} />
                )} />
              </div>
            )}
            </>
          ) : (this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert ? null : 
            <>
              <div>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="guarantee-type"
                  className="m-2"
                  options={this.state.guaranteeTypes}
                  value={this.state.vehicleOfferData.guaranteeType}
                  onChange={(e: any, newValue: IReferential | null) => this.handleGuaranteeTypeChange(newValue)}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="brand"
                      value={this.state.vehicleOfferData.guaranteeType}
                      label={this.translatorService.Tranlate(
                        'SALE_OFFER_GUARANTEE_TYPE',
                        'Tip garantie'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]} />
                  )} />
              </div>
              <div>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="sale-channel"
                  className="m-2"
                  options={this.state.saleChannels}
                  value={this.state.vehicleOfferData.saleChannel}
                  onChange={(e: any, newValue: IReferential | null) => this.handleSaleChannelChange(newValue)}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="sale-channel"
                      value={this.state.vehicleOfferData.saleChannel}
                      label={this.translatorService.Tranlate(
                        'SALE_OFFER_SALE_CHANNEL',
                        'Canal vanzare'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]} />
                  )} />
              </div>
            </>
          )}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.reportService = (this.context as AppContext).reportsService;

    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const caseHasNoVAT =
      offerTypeCode === CaseTypeCode.AQUISITION && this.state.vehicleOfferData.vatValue === 0;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-4">
            {this.renderClientDataForm(!caseHasNoVAT)}

            <Divider></Divider>
            {this.state.offers.length > 0 ? (
              <div className="ml-4 mt-4 timeline-container timeline-list timeline-list--dark text-left">
                {this.showOfferTimeline(!caseHasNoVAT)}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}
        {this.state.contractModalOpened && (
          <ContractModal
            offer={this.state.contractOffer}
            price={
              this.state.contractPrice
                ? this.state.contractPrice * (this.state.contractOffer?.currencyRate || 1)
                : 0
            }
            isSaleOfferType={this.props.isSaleOfferType}
            isOpen={true}
            handleClose={this.closeContractModal}
          />
        )}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabSaleOfferFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabSaleOfferForm as any));
